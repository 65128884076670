// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChatContainer-module__ScrollContainer--x3kCn{position:absolute;height:100%;width:100%;display:block;overflow-y:auto;overflow-y:overlay}.ChatContainer-module__ScrollArea--fK806{display:flex;justify-content:end;flex-direction:column;min-height:100%}", "",{"version":3,"sources":["webpack://./src/reactNodes/ChatContainer.module.scss"],"names":[],"mappings":"AAAA,8CACE,iBAAA,CACA,WAAA,CACA,UAAA,CACA,aAAA,CACA,eAAA,CACA,kBAAA,CAGF,yCACE,YAAA,CACA,mBAAA,CACA,qBAAA,CACA,eAAA","sourcesContent":[".ScrollContainer {\r\n  position: absolute;\r\n  height: 100%;\r\n  width: 100%;\r\n  display: block;\r\n  overflow-y: auto;\r\n  overflow-y: overlay;\r\n}\r\n\r\n.ScrollArea {\r\n  display: flex;\r\n  justify-content: end;\r\n  flex-direction: column;\r\n  min-height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ScrollContainer": "ChatContainer-module__ScrollContainer--x3kCn",
	"ScrollArea": "ChatContainer-module__ScrollArea--fK806"
};
export default ___CSS_LOADER_EXPORT___;
